import React from "react"
import { graphql } from "gatsby"
import ReactStars from 'react-stars'
import TopImage from "../components/top-image"
import Layout from "../components/layout"
import SEO from '../components/seo'
import DynamicColor from '../components/dynamic-color'
import "../styles/avis.scss"

export default function Avis({ data }) {
  const reviews = data.allReviewsJson.nodes

  return (
    <Layout data={data}>
      <SEO/>
      <DynamicColor data={data} />
      <div className="avis-page">
        <TopImage images={data.restaurant.websiteSettings?.restoImages || data.restaurant.websiteSettings?.dishImages || []} />
        <h1>Nos avis</h1>
        <div className="reviews">
          {reviews.map(r => {
            return (
              <div className="review-content">
                <div className="review-top">
                  <img src={`${r.AuthorImage}`} alt="logo" />
                  <div>
                    <strong>{r.authorNames}</strong> - <em>avis google</em>
                    <div className="review-ratings">
                      <ReactStars
                        value={r.ratings}
                        size={14}
                      />
                    &nbsp;&nbsp;{r.date}
                    </div>
                  </div>
                </div>
                <div>
                  {r.content}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}


export const query = graphql`
  query PageQuery4 ($restoId: String!) {
          restaurant(id: {eq: $restoId}) {
          urlName
      informations {
          businessType
        address
        postalCode
        city
        name
        phone
        restoId
      }
      settings {
          color
        takeaway
        delivery
      }
      websiteSettings {
          reservation
        googleMapsUrl
        deliverooUrl
        uberEatsUrl
        logo {
          path
        }
        restoImages {
          path
        }
        linkFacebook
        linkInstagram
        linkYelp
        linkTwitter
        linkFoursquare
        linkTripadvisor
        color
        colorSecond
        colorBackground
      }
    },
    allReviewsJson {
          nodes {
          AuthorImage
        authorNames
        content
        date
        ratings
      }
    }
  }
`